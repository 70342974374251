<template>
  <section
    class="product-card multiple-row-card j-expose__product-item"
    :class="{
      'hover-effect': config.useHoverEffect
    }"
    
    role="listitem"
    tabindex="0"
    :aria-label="goodsName"
    :data-expose-id="`${index}-${goodsInfo.goods_id}`"

    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
  >
    <div 
      class="product-card__top-wrapper"
    >
      <ProductCardDevTool 
        v-if="enableDevTool && supportVueDebug"
        :config="config" 
        :goods-info="goodsInfo"
        :language="language"
      />
      <!-- 主图 start -->
      <ProductCardImgs
        ref="imgContainer"
        :locate-labels-ctrl="locateLabelsCtrl"
        :locate-labels="locateLabels"
        :show-select="showSelect"
        :select-position="selectPosition"
        :select-status="selectStatus"
        :select-disable="selectDisable"
        :show-similar-popup="showSimilarPopup"
        :forbid-jump-detail="forbidJumpDetail"
        :relate-color-info="relateColorInfo"
        :lazy-img="LAZY_IMG_SOLID_COLOR"
        :open-target="openTarget"
        :show-spu-img="_showSpuImg"
        :goods-detail-url="goodsDetailUrl"
        :belt-price-ctrl="beltPriceCtrl"
        :product-design-width="productDesignWidth"
        :crop-rate="cropRate"
        :no-img-lazy="noImgLazy"
        :report-metrics="reportMetrics"
        :comp-src="compSrc"
        @click-item="handleClickItem($event, { from: 'mainImg' })"
        @select="handleSelect"
      >
        <!-- 图片上层slot(嵌套) -->
        <template #imgContent>
          <slot name="soldout"></slot>
        </template>

        <!-- 图片底部slot(嵌套) -->
        <template #imgBottom>
          <slot name="imgBottom"></slot>
        </template>

        <!-- 图片底部slot(盖在腰带之上) -->
        <template #imgBottomInTop>
          <slot name="imgBottomInTop"></slot>
        </template>
      </ProductCardImgs>

      <!-- 加车以及找相似按钮 -->
      <ClientOnly>
        <ProductCardOperation
          v-if="showOperation"
          ref="itemOperation"
          :sold-out="saleoutShow"
          @addboard="emit('addboard', item)"
          @delete-item="emit('deleteItem', item)"
        >
          <template #addToBagBtn>
            <slot name="addToBagBtn"></slot>
          </template>
        </ProductCardOperation>
  
        <!-- 主图加载中 -->
        <s-product-item-loading 
          v-if="showLoading" 
          :show="showLoading" 
        />

        <!-- 主图左上角 -->
        <div class="top-wrapper__top-left">
          <slot 
            name="imgTopLeft" 
            :final-price-and-discount-info="finalPriceAndDiscountInfo"
          ></slot>
        </div>

        <!-- client下 商品图片区域的slot -->
        <slot name="clientGoodsImg"></slot>
      </ClientOnly>

      <!-- 商品图片区域的slot -->
      <slot 
        name="goodsImg"
        :final-price-and-discount-info="finalPriceAndDiscountInfo"
      ></slot>
    </div>
    <!-- 主图 end -->

    <div
      class="product-card__bottom-wrapper"
      :class="{ 'bottom-wrapper__saleout': saleoutShow }"
      :style="bottomInfoStyle"
    >
      <DeliveryWords
        v-if="showDeliveryWords"
        :goods-id="goodsInfo.goods_id"
        :goods-sn="goodsInfo.goods_sn"
        :bff-goods-info="goodsInfo.bffGoodsInfo"
        :cat-id="goodsInfo.cat_id"
        :delivery-info="deliveryInfo"
        :store-info="storeInfo"
        :custom-cate-ids="goodsInfo.custom_cate_ids"
        :is-mod-store-tag="storeInfo?.isModStore"
        :is-choice-store-tag="storeInfo?.isChoiceStore"
        :is-choice="!!deliveryInfo?.dpPoolId"
      />
      <!-- 标题上方插槽 -->
      <slot name="goodsTitleTop"></slot>
      <slot name="cardTitleTop"></slot>
      
      <!-- 商品名称 -->
      <ProductItemTitleContainer
        ref="nameContainer"
        @click-goods-name="handleClickItem($event, { from: 'cardInfo' })"
      >
        <template #beforeTitle>
          <slot name="beforeTitle"></slot>
        </template>
      </ProductItemTitleContainer>

      <!-- 标题下方插槽 -->
      <slot name="goodsTitleBottom"></slot>

      <!-- 卖点信息区域 -->
      <!-- 榜单、星级评论、用户行为标签等 -->
      <!-- 榜单卖点信息区域 或 赛马标签信息区域 -->
      <slot
        v-if="config.showLabelCarousel"
        name="labelCarousel"
      ></slot>
      <ProductCardHorseRaceLabelContainer
        v-else-if="config.showHorseRaceLabel"
      />

      <ProductCardSellingPropositionContainer
        v-else-if="!config.hideShellingPoint"
      />

      <slot name="cardPriceTop"></slot>

      <div 
        v-if="showAddBagBtn || !config.hideAllBottomPrices"
        class="bottom-wrapper__price-wrapper"
        :class="{
          'part-transparent': delBtnVisible // 部分透明
        }"
      >
        <!-- 价格相关 -->
        <ProductCardPricesContainer 
          v-if="!config.hideAllBottomPrices"
          :show-select="showSelect"
          :show-spu-img="_showSpuImg"
          :belt-label="beltPriceCtrl.beltLabel"
          :is-promo-price="beltPriceCtrl.isPromoPrice"
          :sold-out="saleoutShow"
        />

        <!-- 加车按钮 -->
        <ProductCardAddBagBtn
          class="price-wrapper__addbag-btn"
          :sold-out="saleoutShow"
          @open-quick-add="handelOpenQuickAdd"
        >
          <template
            v-if="$slots.addToBagBtn"
            #addToBagBtn
          >
            <slot name="addToBagBtn"></slot>
          </template>
        </ProductCardAddBagBtn>
        <!-- 删除按钮 -->
        <ProductCardDelBtn
          v-if="delBtnVisible"
          class="price-wrapper__del-btn"
          @delete-item="emit('deleteItem', item)"
        />
      </div>

      <!-- 为保持加车按钮居中，合规价显示时此处显示
          建议零售价 -->
      <SuggestedSalePriceContainer
        v-if="priceBottomRrp.show"
        :goods-info="goodsInfo"
        :price-data="priceBottomRrp"
      />

      <div class="bottom-wrapper__labels">
        <slot name="reducePrice"></slot>
      </div>
      <!-- 服务类标签 -->
      <ProductCardLabelsContainer
        v-if="!config.hideServiceTag"
      >
        <template #goodsServiceTag>
          <slot name="goodsServiceTag"></slot>
        </template>
      </ProductCardLabelsContainer>

      <!-- 商铺跳转链接 -->
      <ProductCardStoreLink 
        v-if="showStoreBar" 
        :store-info="storeInfo"
        :goods-id="`${goodsInfo.goods_id}`"
        :cat-id="`${goodsInfo.cat_id}`"
      />
      <!-- buy box -->
      <ProductCardBuyBoxEntrance
        v-if="buyBoxCount" 
        :count="buyBoxCount"
        :price="buyBoxPrice"
        :language="language"
        :goods-id="goodsInfo.goods_id"
        :css-right="GB_cssRight"
        @open-buy-box-drawer="openBuyBoxDrawer"
      />

      <!-- 商品底部slot -->
      <slot name="goodsBottom"></slot>
    </div> 

    <!-- 默认内容插槽 -->
    <slot name="goodsMain"></slot>
  </section>
</template>

<script name="MultipleImageRowCard" setup>
import { toRef, computed, defineAsyncComponent, provide, useSlots } from 'vue'

// components start
import { ClientOnly } from '@sheinfe/vue-client-only'
import { ProductCardImgs } from './components/ProductCardImgs'
import { ProductCardAddBagBtn } from './components/ProductCardAddBagBtn'
import ProductCardPricesContainer from './components/ProductCardPrice/ProductCardPricesContainer.vue'
import ProductItemTitleContainer from './components/ProductCardTitle/ProductItemTitleContainer.vue'
import ProductCardSellingPropositionContainer from './components/ProductCardSellingProposition/ProductCardSellingPropositionContainer.vue'
import ProductCardHorseRaceLabelContainer from './components/ProductCardSellingProposition/ProductCardHorseRaceLabelContainer.vue'
import ProductCardLabelsContainer from './components/ProductCardLabels/ProductCardLabelsContainer.vue'
import ProductCardBuyBoxEntrance from './components/ProductCardBuyBoxEntrance/index.vue'
import ProductCardDelBtn from './components/ProductCardDelBtn/index.vue'
import { DeliveryWords } from './components/DeliveryWords/index.js'
import SuggestedSalePriceContainer from './components/ProductCardPrice/SuggestedSalePriceContainer.vue'

// import ProductCardDevTool from './components/dev-tool/Index.vue'
const ProductCardDevTool = defineAsyncComponent(() => import('./components/dev-tool/Index.vue'))
const ProductCardOperation = defineAsyncComponent(() => import('./components/ProductCardOperation'))
const SProductItemLoading = defineAsyncComponent(() => import('./components/ProductItemLoading.vue'))
const ProductCardStoreLink = defineAsyncComponent(() => import('./components/ProductCardStoreLink/ProductCardStoreLink.vue'))
// const ProductCardBuyBoxEntrance = defineAsyncComponent(() => import('./components/ProductCardBuyBoxEntrance/index.vue'))
// components end

import { cardProps, cardEmit, useCardMain } from './hooks/useCardMain.js'
import useBuyBoxEntrance  from './hooks/useBuyBoxEntrance.js'

// utils
import { views } from './utils/constant'

// 单列与多列取值key不同
const labelsFromKey = views.LIST_PC_STANDARD_VIEW

const emit = defineEmits(cardEmit)

const props = defineProps(Object.assign(
  {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  }, 
  cardProps
))

const slots = useSlots()
provide('slots', slots)

const {
  supportVueDebug,

  // data
  relateColorInfo,
  goodsDetailUrl,

  // static config
  GB_cssRight, 
  LAZY_IMG_SOLID_COLOR,

  // props
  item, 
  config, 
  language, 
  showSelect, 
  selectPosition,
  index, 
  showSimilarPopup, 
  selectStatus, 
  selectDisable,
  bottomInfoStyle, 
  openTarget, 
  productDesignWidth,
  noImgLazy,
  reportMetrics,

  // dom ref
  imgContainer,
  itemOperation,
  nameContainer,

  goodsInfo,

  locateLabels,
  locateLabelsCtrl,

  _showSpuImg,

  finalPriceAndDiscountInfo,

  saleoutShow,
  beltPriceCtrl,
  goodsName,
  delBtnVisible,
  showLoading,

  forbidJumpDetail,

  storeInfo,
  showStoreBar,
  deliveryInfo,
  showDeliveryWords,

  showOperation,
  showAddBagBtn,
  handelOpenQuickAdd,

  handleClickItem,
  handleSelect,

  handleMouseenter,
  handleMouseleave,

  setElAttr,
  setAttrForAnalysis,
  customizeElAttr,

} = useCardMain({
  emit,
  props,
  labelsFromKey
})

const {
  buyBoxCount,
  buyBoxPrice,
  openBuyBoxDrawer
} = useBuyBoxEntrance(toRef(props, 'item'), emit)

const priceBottomRrp = computed(() => {
  const { priceBottom, priceBottomRrp  } = finalPriceAndDiscountInfo.value
  if ( !priceBottom.show || labelsFromKey === views.LIST_PC_BROADWISE_VIEW ) return {}

  return priceBottomRrp
})

// 外部ref调用
defineExpose({
  setElAttr,
  setAttrForAnalysis,
  customizeElAttr,
})

</script>


<style lang="less">
@import url(./style/checkBox.less);
@import url(./style/icon.less);

.multiple-row-card {
  font-size: 0;

  background-color: var(--product-card-bg);
  background-image: var(--product-card-bg-image);
  &.hover-effect:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  }
  .product-card__top-wrapper {
    position: relative;

    border-radius: var(--product-card-top-wrapper-border-radius, 0);
    overflow: hidden;
    .top-wrapper {
      // 主图左上区域
      &__top-left {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 100%;
      }
    }
  }

  .product-card__bottom-wrapper {
    min-height: 74px;
    padding-top: 6px;
    .bottom-wrapper__price-wrapper {
      margin-top: 4px;
      
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .price-wrapper__addbag-btn {
      flex: 0 0 auto;
    }
  }

  .bottom-wrapper__saleout {
    & > div, & > section {
      opacity: 0.3;
    }
    
    // 色块选择不受影响
    & > .product-card__relatecolor-container {
      opacity: 1;
    }

    & > .part-transparent {
      opacity: 1;
      > * {
        opacity: 0.3;
      }
      // 删除按钮不透明
      /* stylelint-disable selector-max-specificity */
      .price-wrapper__del-btn {
        opacity: 1;
      }
    }
  }
}
</style>

