import { computed } from 'vue'

export default function useBuyBoxEntrance(item, emit){
  const buyBoxCount = computed(() => item.value?.buyBoxCount?.count || 0)
  const buyBoxPrice = computed(() => item.value?.buyBoxPrice?.price || '')

  const openBuyBoxDrawer = () => emit('openBuyBoxDrawer', {
    cat_id: item.value?.cat_id,
    goods_id: item.value?.goods_id,
    goods_sn: item.value?.goods_sn || '',
    mall_code: item.value?.mall_code || '',
  })
  return {
    buyBoxCount,
    buyBoxPrice,
    openBuyBoxDrawer,
  }
}
